/* custom.css */

body {
    font-family: 'Roboto', sans-serif;
  }
  
  /* Add additional styles for Bootstrap components if needed */

.btn-lg:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2)
}  
