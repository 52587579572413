/* custom.css */

body {
    font-family: 'Roboto', sans-serif;
  }
  
  /* Add additional styles for Bootstrap components if needed */

.btn-lg:hover {
  transform: scale(1.2)
}  